<template>
  <div class="management bj">
    <div class="title">
      <el-button type="primary" icon="el-icon-refresh" class="bjsearch" @click="Refresh">
      </el-button>
      <el-button type="primary" @click="handelAdd">添加</el-button>
    </div>
    <div class="auto-table-flex">
      <el-table :data="tableData" height="100%" v-loading="loading" :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }">
        <el-table-column prop="fullname" align="center" label="姓名">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="电话">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.mobile) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button type="text" @click="delHandle(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--  添加、编辑管理员弹框  -->
    <el-dialog :title="title" :visible.sync="centerDialogVisible" v-if="centerDialogVisible" width="571px" class="dialog"
      center>
      <div class="contents">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名" prop="fullname">
            <el-input v-model="ruleForm.fullname" placeholder="请输入姓名" style="width: 302px"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号" style="width: 302px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="disabled" @click="add">确认</el-button>
      </span>
    </el-dialog>

    <div class="fy">
      <el-pagination type="primary" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="from.page" :page-sizes="[10, 20, 50, 100]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "adminment",
  components: {},
  data() {
    return {
      loading: false,
      from: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      roleList: [],
      total: 0,
      centerDialogVisible: false,
      disabled: false,
      showid: false,
      title: '',
      manageIsdentity: '',
      ruleForm: {
        id: '',
        fullname: '',
        mobile: '',
      },
      rules: {
        fullname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          {
            required: true,
            message: '请输入联系电话',
            // blur和change事件触发检验
            trigger: ['blur', 'change'],
          },
          // 正则判断为字母或数字
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            // 正则检验前先将值转为字符串
            transform(value) {
              return String(value);
            },
            message: '该手机号码不符合规范',
            trigger: ['blur']
          }
        ],
      },
    };
  },
  created() {
    this.manageIsdentity = sessionStorage.getItem("manageIsdentity");
    this.getMemberList();
  },
  methods: {
    // 获取列表数据
    getMemberList() {
      this.loading = true;
      // 总控中心
      this.$api.logistics.getControlList(this.form).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
      this.loading = false;
    },
    delHandle(row) {
      this.$confirm(
        "确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.logistics
            .delControlList({
              id: row.id
            })
            .then((res) => {
              this.tool.message('删除成功', 'success');
              this.getMemberList();
            });
        })
    },
    // 刷新
    Refresh() {
      this.getMemberList();
    },
    // 分页选择显示数量
    handleSizeChange(val) {
      this.from.page = 1
      this.from.pageSize = val;
      this.getMemberList();
    },
    // 分页选择当前页
    handleCurrentChange(val) {
      this.from.page = val
      this.getMemberList();
    },
    // 添加
    handelAdd() {
      this.ruleForm = {
        id: '',
        fullname: '',
        mobile: ''
      };
      this.disabled = false
      this.centerDialogVisible = true;
      this.title = '添加'
    },
    add() {
      this.disabled = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.title == '添加') {
            this.$api.logistics.addControlList({
              fullname: this.ruleForm.fullname,
              mobile: this.ruleForm.mobile
            }).then((res) => {
              if (res.code == 20000) {
                this.tool.message('添加成功', 'success')
                this.centerDialogVisible = false
                this.getMemberList()
              } else {
                this.disabled = false
              }
            }).catch(error => {
              this.disabled = false
            })
          } else {
            this.$api.logistics.updateControlList({
              id: this.ruleForm.id,
              fullname: this.ruleForm.fullname,
              mobile: this.ruleForm.mobile
            }).then((res) => {
              if (res.code == 20000) {
                this.tool.message('编辑成功', 'success')
                this.centerDialogVisible = false
                this.getMemberList()
              } else {
                this.disabled = false
              }
            }).catch(error => {
              this.disabled = false
            })
          }
        } else {
          this.disabled = false
        }
      })
    },
    // 添加用户
    insert() {
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.addGeneralUser(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        })
      }
    },
    // 修改
    handleUpdate(row) {
      for (let key in this.ruleForm) {
        this.ruleForm[key] = row[key]
      }
      this.disabled = false
      this.centerDialogVisible = true;
      this.title = '编辑'
    },
  }
};
</script>
<style lang="scss">
.management {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    .titlname {
      font-size: 17px;
      color: #349EE6;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
        }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 100px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}

.colors {
  color: #fa884c;
}
</style>
  